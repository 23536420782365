.header {
  display: flex;
  border-bottom: 4px solid #000;
  padding: 20px 60px;
  position: sticky;
  top: 0;
  background: #000;
  z-index: 99;
  align-items: center;
}
.header-logo {
  flex: 1;
  justify-content: flex-start;
  display: flex;
}
.header-menu {
  display: flex;
}
.header-menu a {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  display: inline-block;
  width: 146px;
  height: 42px;
  line-height: 42px;
}

.header-menu a.register-button {
  width: 146px;
  height: 42px;
  flex-shrink: 0;
  border-radius: 50px;
  background: linear-gradient(to bottom right, #01DB83 8.5%, #6D63F4 100%);
  text-align: center;
  color: #000;
  font-size: 18px;
  font-weight: 800;
  line-height: 42px; 
}


@media screen and (max-width: 1024px) {
  .header {
    padding: 16px 20px
  }
  .header .mh {
    display: none;
  }
  .header-logo img {
    height: 24px;
  }
  .header-menu a.register-button {
    width: 106px;
    height: 32px;
    line-height: 32px;
    font-size: 15px;
  }
}
