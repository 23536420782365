.onboard {
  position: relative;
}

.onboard img.onboard-bg {
  width: 100%;
  top: -130px;
  position: relative;
  z-index: 2;
}
.onboard img.onboard-boat {
  max-height: 420px;
  position: absolute;
  bottom: 0;
  left: 120px;
  z-index: 1;
}
.onboard-linear {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: linear-gradient(3600deg, #000 0%, rgba(0, 0, 0, 0.00) 100%);
  height: 92px;
  flex-shrink: 0;
}
.onboard-time {
  font-size: 38px;
  align-items: center;
  margin-bottom: 80px;
  text-align: center;
}
.onboard-text {
  position: relative;
  z-index: 2;
  padding-top: 56px;
  background: #000;
}
.onboard-text h1 {
  font-size: 66px;
  font-style: normal;
  font-weight: 900;
  line-height: 64px;
  text-align: center;
}
.onboard-text h1 {
  /* background: linear-gradient(to bottom right, #01DB83 8.5%, #6D63F4 100%); */
  background: linear-gradient(272deg, #6D63F4 38.53%, #01DB83 60.44%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.onboard-text p {
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  text-align: center;
  max-width: 700px;
  margin: 24px auto;
}
.onboard-text p strong {
  font-style: italic;
  font-weight: 800;
}
html {
  background: #000;
}
body {
  background-image: url(./assets/about-bg.png);
  background-size: contain;
}
.about {
  /* padding-top: 74px; */
  position: relative;
  top: -140px;
  /* padding-bottom: 85px; */
  /* background: url(./assets/about-bg.png) no-repeat; */
  /* background-size: contain; */
  text-align: center;
}
.about > p:first-of-type{
  background: linear-gradient(272deg, #6D63F4 38.53%, #01DB83 60.44%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 36px;
  font-weight: 800;
  margin-bottom: 12px;
  line-height: 56px !important;
}
.about p {
  max-width: 1024px;
  margin: 0 auto;
  font-size: 24px;
  font-style: italic;
  /* font-weight: 800; */
  line-height: 30px;
  max-width: 615px;
}
.about p.clip {
  background: linear-gradient(271deg, #01DB83 25.24%, #FAFF00 90.03%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.about p.clip span {
  -webkit-text-fill-color: aliceblue;
}
.about p.about-text {
  font-size: 22px;
  font-weight: 400;
  font-style: normal;
  flex: 1;
  word-break: break-all;
}
.about strong {
  font-weight: 800;
}
.about div p {
  font-size: 18px;
  text-align: left;
}
.about div {
  display: flex;
  /* justify-content: center; */
  max-width: 1200px;
  justify-content: center;
  margin: 36px auto;
  gap: 24px;
}
.about div a {
  height: 80px;
  display: flex;
  align-items: center;
  border: 1px solid #FFFFFF;
  border-radius: 50px;
  padding-left: 36.5px;
  font-size: 22px;
  font-style: normal;
  font-weight: 800;
  line-height: 40px;
  transition: all 300ms;
}
.about div a.register {
  background: #FFF;
  color: #000;
}
.about div a.register span {
  background-color: #000;
  margin-left: 56.75px;
}
.about div a.telegram span {
  background-color: #fff;
  margin-left: 36.5px;
}
.about div a.telegram:hover span {
  background-color: #000;
}
.about div a.telegram:hover span svg path {
  fill: #fff;
}
.about div a:hover{
  background: linear-gradient(to bottom right, #01DB83 8.5%, #6D63F4 100%);
  border-color: #000;
  color: #000;
}

.about div a.telegram span, .about div a.register span {
  border-radius: 50px;
  display: flex;
  width: 55px;
  height: 55px;
  margin-right: 13px;
  align-items: center;
  justify-content: center;
}

.about h2 {
  font-size: 42px;
  font-style: normal;
  font-weight: 900;
  line-height: 64px;
  margin-top: 56px;
  margin-bottom: 32px;
}
/* .agenda-container { */
  /* background-image: url(./assets/agenda.png);
  background-size: contain;
  background-repeat: no-repeat; */
/* } */
.agenda {
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 56px;
}
.agenda-head {
  display: flex;
  margin-bottom: 36px;
  margin-top: 60px;
  gap: 16px;
}

.agenda-head button {
  border: 0;
  height: 78px;
  font-size: 28px;
  font-weight: 900;
  flex: 1;
  border-radius: 12px;
  background: transparent;
  color: #fff;
  border: 1px solid #fff;
  cursor: pointer;
}
.agenda-head button.active {
  background: linear-gradient(to bottom right, #01DB83 8.5%, #6D63F4 100%);
  border: 0;
}
/* .agenda-head div {
  margin-right: 24px;
}
.agenda-head div p {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
}
.agenda-head div h3 {
  font-size: 56px;
  margin-top: 12px;
  font-style: normal;
  font-weight: 900;
  line-height: 56px;
  display: flex;
  align-items: flex-start;
}
.agenda-head div h3 span {
  font-size: 32px;
  position: relative;
  top: -10px;
}
.agenda-head div:last-of-type h3 {
  font-weight: 800;
} */
.agenda-schedule li {
  height: 86px;
  display: flex;
  /* grid-template-columns: repeat(3, 1fr); */
  gap: 24px;
  align-items: center;
  padding-left: 24px;
  /* padding-right: 24px; */
  color: #000;
  border-radius: 12px;
  margin-top: 12px;
}
.agenda-schedule li.ih {
  min-height: 290px;
}
.agenda-schedule li.iht {
  min-height: 360px;
}

.agenda-schedule li.bg-yellow {
  background-color: #FAFF00;
}
.agenda-schedule li.bg-white {
  background-color: #fff;
}
.agenda-schedule li.bg-green {
  background-color: #01DB83;
}


.agenda-schedule li > div.agenda-content-first {
  display: flex;
  flex: 1;
}
.agenda-schedule li > div.agenda-content-first p:last-of-type {
  flex: 1;
}
.agenda-schedule li > div > p:first-of-type {
  width: 280px;
}
.agenda-schedule li > div > p {
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: 24px;
  display: flex;
  align-items: center;
  word-break: break-all;
}
.agenda-schedule li > div > p span {
  border-radius: 4.909px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  padding: 6px;
  margin-left: 12px;
  display: inline-block;
}
@media screen and (max-width: 1024px) {
  .agenda-schedule li > div > p span {
    margin-left: 4px;
  }
}
.agenda-schedule li > div > p span.yellow {
  background: #FFC731;
}
.agenda-schedule li > div > p span.blue  {
  background-color: #23E0FF;
  height: 24px;
  display: inline-block;
  padding: 0;
  line-height: 24px;
  padding-left: 6px;
  padding-right: 6px;
}
.agenda-schedule li > div > p span.panel {
  background: #B196FF;
}

.agenda-schedule li > p.fw-800 {
  font-size: 22px;
  font-weight: 800;
}
.agenda-schedule li > div.agenda-content-last {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 12px 0;
}
.agenda-schedule li.bg-yellow > div.agenda-content-last > div {
  background-color: #DFE400;
}
.agenda-schedule li > div.agenda-content-last > div {
  flex: 1;
}
.agenda-schedule li > div.agenda-content-last > div {
  display: flex;
  padding-left: 25px;
  background-color: #E8E8E8;
  border-radius: 0 12px 12px 0;
  align-items: center;
  width: 328px;
}
.agenda-schedule li > div.agenda-content-last img {
  width: 56px;
  height: 56px;
  object-fit: cover;
  margin-right: 10px;
  border-radius: 100%;
}
.agenda-schedule li > div.agenda-content-last h6 {
  font-size: 16px;
  font-weight: 800;
  line-height: 24px;
}
.agenda-schedule li > div.agenda-content-last h6 span.host {
  font-size: 12px;
  font-weight: 800;
  line-height: 1;
  padding: 2px 4px 2px 4px;
  background: #01DB83;
  border-radius: 20px;
}
.agenda-schedule li > div.agenda-content-last p {
  font-size: 14px;
  font-weight: 400;
  Line-height: 14px;
  opacity: 0.60;
}
.agenda-schedule li > div.agenda-content-last p:last-child {
  margin-top: 4px;
}

.speakers {
  position: relative;
  max-width: 1280px;
  margin: 0 auto;
  overflow: hidden;
}
.speakers h3 {
  font-size: 42px;
  font-style: normal;
  font-weight: 900;
  line-height: 64px;
  margin-top: 112px;
  
  text-align: center;
}
.speakers > p {
  text-align: center;
  font-size: 18px;
  margin-bottom: 36px;
}
.speakers-content {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 36px;
}
.speakers-content > div {
  border: 2px solid #fff;
  display: flex;
  flex-direction: column;
}
.speakers-img {
  padding: 16px;
}
.speakers-img img {
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
}
.speakers-text {
  padding: 12px 14px;
  border-top: 2px solid #fff;
  height: 100%;
}
.speakers-text h6 {
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: 30px; 
  margin-bottom: 2px;
}
.speakers-text p {
  font-size: 13.6px;
  font-weight: 400;
  line-height: 14.7px;
}
.speakers-content > div .speakers-text {
  transition: all 300ms;
}
.speakers-content > div:hover {
  border: 2px solid;
  border-image-source: linear-gradient(to bottom, #FAFF00, #01DB83);
  border-image-slice: 1;
}
.speakers-content > div:hover .speakers-text {
  border-color: transparent;
  color: #000;
  background: linear-gradient(100deg, #FAFF00 8.5%, #01DB83 60.3%);
}
.CPCM {
  padding-bottom: 100px;
  padding-top: 110px;
}
.CPCM h2 {
  font-size: 42px;
  font-style: normal;
  font-weight: 900;
  line-height: 64px;
  margin-top: 20px;
  text-align: center;
  margin-bottom: 24px;
}
.CPCM-content {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(6, 1fr);
  max-width: 1280px;
  gap: 12px;
  margin: 0 auto;
}
.CPCM-content div {
  display: flex;
  align-items: center;
  /* padding: 22px 32px; */
  font-size: 20.275px;
  font-style: normal;
  font-weight: 400;
  border-radius: 8px;
  background: rgba(90, 90, 90, 0.20);
  backdrop-filter: blur(4px);
  justify-content: center;
  height: 140px;
}
.CPCM-content div.hasBg {
  background: linear-gradient(100deg, #FAFF00 8.5%, #01DB83 60.3%);
}
.CPCM-content div.hasBg {
  display: flex;
  flex-direction: column;
}
.CPCM-content div.hasBg svg {
  margin-top: 20px;
}
.CPCM-content div.hasBg path {
  fill: #000;
}
/* .CPCM-content div img {
  margin-right: 14px;
} */
.footer {
  background: linear-gradient(100deg, #FAFF00 8.5%, #01DB83 60.3%);
  color: #000;
  padding: 64px 0;
}
.footer > div {
  display: flex;
  max-width: 1280px;
  margin: 0 auto;
  align-items: center;
}
.footer-contact {
  display: flex;
  flex: 1;
}
.footer-contact h1 {
  font-size: 64px;
  font-style: normal;
  font-weight: 900;
  line-height: 72px; 
  margin-bottom: 16px;
}
.footer-contact p {
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 42px;
}
.footer-contact > div:last-of-type a {
  width: 132px;
  height: 132px;
  border-radius: 132px;
  border: 1px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 64px;
  transition: all 300ms;
  overflow: hidden;
}
.footer-contact > div:last-of-type a svg {
  transition: all 300ms;
}
.footer-contact > div:last-of-type a:hover {
  background: #000;
}
.right-arrow {
  transform: translateX(20px);
}
.footer-contact > div:last-of-type a:hover .right-arrow {
  transform: translateX(200px);
}
.left-arrow {
  transform: translateX(-200px);
}
.footer-contact > div:last-of-type a:hover .left-arrow {
  transform: translateX(-20px);
}
.footer-contact > div:last-of-type a:hover svg path {
  fill: white;
}
.got {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 200% */
  color: #000;
  justify-content: center;
  transition: all 300ms;
}
.got:hover svg {
  margin-left: 12px;
}
.got svg {
  margin-left: 8px;
  transition: all 300ms;
}
.open-email {
  display: none;
}
.venue {
  max-width: 1115px;
  margin: 0 auto;
  display: flex;
  margin-bottom: 56px;
}
.venue > svg {
  width: 160px;
}
.venue-map {
  border: 4px solid #fff;
  margin-left: 56px;
  margin-right: 56px;
  padding: 20px;
}
.venue-map img{
  max-width: 100%;
  object-fit: cover;
  height: 100%;
}
.venue-desc {
  max-width: 341px;
}
.venue-desc h3 {
  margin-bottom: 24px;
  font-size: 26px;
  line-height: 37px;
  font-weight: 900;
  margin-top: 23px;
}
.venue-desc p {
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  margin-bottom: 50px;
}
@media screen and (max-width: 1024px) {
  .onboard {
    padding: 0 20px;
    /* overflow: hidden; */
    background: #000;
  }
  .onboard-text {
    padding-top: 24px;
  }
  .onboard-text h1 {
    font-size: 38px;
    line-height: 38px;
    margin-top: 24px;
  }
  .onboard-text p {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 0;
  }
  .onboard img.onboard-boat {
    width: 180px;
    left: 20px;
  }
  .onboard img.onboard-bg {
    width: 200%;
    left: -50%;
    top: 60px;
  }
  .about {
    padding: 0 20px;
    margin-top: 180px;
  }
  .about p {
    font-size: 18px;
    line-height: 32px;
  }
  .about h2 {
    font-size: 28px;
    line-height: 38px;
    margin: 24px 0;
  }
  .about div {
    margin-top: 0;
    font-size: 18px;
    line-height: 28px;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 0;
  }
  .about div p {
    text-align: center;
  }
  .about div a {
    height: 60px;
    width: 228px;
    font-size: 16.5px;
    line-height: 30px;
  }
  .about div a.telegram {
    margin-right: 0;
    margin-bottom: 12px;
  }
  .about div a.telegram span, .about div a.register span {
    margin-right: 9px;
    width: 41.25px;
    height: 41.25px;
  }
  .about div a.telegram span {
    margin-left: 54.5px;
  }
  .about div a.register span {
    margin-left: 113.75px;
  }
  .about p.about-text {
    margin-top: 16px;
    font-size: 16px;
    margin-bottom: 16px;
  }
  .agenda {
    padding: 0 20px 50px;
  }
  .agenda-head div p {
    font-size: 10px;
    line-height: 20px; 
  }
  .agenda-head div h3 {
    font-size: 28px;
    line-height: 21px;
    margin-top: 6px;
  }
  .agenda-head div h3 span {
    font-size: 16px;
  }
  .agenda-schedule li {
    display: block;
  }
  .agenda-schedule li > p {
    font-size: 18px;
  }
  .agenda-schedule li > p span {
    margin-left: 0;
    font-size: 12px;
  }
  .agenda-schedule li {
    grid-template-columns: repeat(2, 1fr);
    min-height: 56px;
    height: fit-content !important;
    padding-left: 0;
  }
  .agenda-content-lastyellow {
    padding: 0 !important;
  }
  .agenda-content-last > div {
    margin: 0 !important;
  }
  .agenda-schedule li > p.fw-800 {
    font-size: 18px;
    line-height: 22px;
  }
  .agenda-schedule li > div.agenda-content-last {
    width: 100%;
    padding-left: 0;
    background-color: #E8E8E8;
    border-radius: 0 0 12px 12px;
    gap: 12px;
  }
  .agenda-schedule li > div.agenda-content-last > div:last-child {
    padding-bottom: 12px;
  }
  .agenda-content-first {
    padding: 0 20px;
  }
  .agenda-schedule li > div > p {
    font-size: 18px;
    line-height: 24px;
  }
  .agenda-schedule li > div > p:first-of-type {
    width: 140px;
  }
  .agenda-schedule li > div > p:first-of-type {
    display: block;
  }
  .agenda-schedule li > div > p span.yellow {
    display: block;
    width: fit-content;
    margin-left: 0;
  }
  .agenda-schedule li > div.agenda-content-first {
    padding: 20px;
    align-items: flex-start;
  }
  .agenda-schedule li > div.agenda-content-last {
    padding-bottom: 0;
  }
  .agenda-schedule li > div.agenda-content-last > div {
    border-radius: 0 0 12px 12px;
    width: 88%;
    margin-bottom: 12px;
  }
  .agenda-content-last-yellow {
    background-color: #DFE400 !important;
    border-radius: 0 0 12px 12px;
  }
  .agenda-head button {
    font-size: 16px;
    height: 56px;
  }
  .agenda-head button span {
    display: none;
  }
  .speakers > p {
    margin-bottom: 24px
  }
  .speakers h3 {
    font-size: 28px;
    margin-top: 16px;
    line-height: 38px;
    margin-top: 6px;
    /* margin-bottom: 30px; */
  }
  .speakers-content {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    padding: 20px;
  }
  .speakers-text {
    padding: 4px 8px;
  }
  .speakers-text h6 {
    font-size: 13.5px;
    line-height: 18px;
  }
  .speakers-text p {
    font-size: 12px;
  }
  .CPCM-content {
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(2, 1fr);
    padding: 0 20px;
  }
  .CPCM-content div {
    padding-left: 10%;
    padding-right: 10%;
  }
  .CPCM-content img {
    width: 100%;
  }
  .CPCM-content .hasBg img {
    width: 80%;
  }
  .CPCM-content .hasBg img.host {
    width: 30%;
  }
  .CPCM-content .hasBg img.community {
    width: 72%;
  }
  .CPCM-content .hasBg img.university {
    width: 60%;
  }
  .CPCM-content .hasBg img.media {
    width: 50%;
  }
  .CPCM {
    padding-top: 20px;
  }
  .CPCM h2 {
    font-size: 28px;
    line-height: 38px;
  }
  .footer-contact {
    display: none;
  }
  .footer > div {
    justify-content: center;
  }
  .open-email {
    display: block;
  }
  .CPCM-content img.w-55 {
    width: 60%;
  }
  .venue {
    flex-direction: column;
    margin-top: -150px;
  }
  .venue > svg {
    transform: rotate(-90deg) translateY(40%);
    width: 28px;
  }
  .venue-map {
    margin-left: 20px;
    margin-right: 20px;
  }
  .venue-map img {
    max-width: 100%;
  }
  .venue-desc {
    max-width: 100%;
    margin-left: 20px;
    margin-right: 20px;
  }
  .venue-desc p {
    margin-bottom: 20px;
  }
}
/* @media screen and (max-width: 992px) {
  .onboard {
    text-align: center;
    padding-top: 37.5px;
    padding-left: 12px;
    padding-right: 12px;
  }
  .onboard-time {
    justify-content: center;
    font-size: 19px;
    margin-bottom: 36px;
  }
  .onboard-time svg {
    width: 20px;
    height: 20px;
  }
  .onboard h2 {
    display: inline;
    font-size: 36px;
    font-style: normal;
    font-weight: 900;
    line-height: 42px; 
    letter-spacing: -0.72px;
  }
  .onboard h2 span {
    display: none;
  }
  .onboard > p {
    display: block;
    margin-top: 18px;
  }
  

  
  .faq > div {
    padding: 0 20px;
    padding-bottom: 100px;
  }
} */
